export default {
  address: {
    contactNumberLength: 10, // include start with zero or not
    contactNumberMinLength: 11,
    contactNumberStartWithZero: true,
    emailRequired: false,
    hidePin: false,
    hideCountry: false,
    fixCountry: 'TH',
    pinCodeRequired: true,
    pinCodeLength: 5,
    pinCodeDigitOnly: true,
    addressLine2Mandatory: true,
  },
  allowInsufficientPoint: false,
  allowPayment: false,
  app: 'mastercard',
  applyBgColorFromDjango: false,
  appName: 'Benefits Program',
  contactInfo: {
    en: '',
  },
  customFeeds: true,
  customRewardCategory: true,
  defaultCurrency: 'HKD',
  defaultLanguage: 'en',
  defaultUserIcon: 'assets/img/user.png',
  editFormDisabled: true,
  employeeIdPrefix: 'mastercard',
  enableNomination: false,
  enforceDefaultLanguage: true,
  expiryPoint: true,
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  filterByPoints: false,
  sortByPoint: false,
  freshchat: {
    isShow: false,
    pages: ''
  },
  forgotPasswordLink: false,
  footer: {
    dynamicLogo: true,
    logoLink: '',
    privacyLink: '',
    tncLink: '',
    userGuide: '',
    showContactUS: false
  },
  header: {
    dynamicBg: false,
    dynamicLogo: true,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: false,
    isIconSvg: false
  },
  hideSFStoreSFLocker: true,
  home: {
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
  },
  languageAvailable: ['en', 'ja'],
  languageLabel: {
    en: 'English',
    ja: '日本語'
  },
  loginModal: true,
  loginPage: false,
  loginReadMore: false,
  loginTermsCondition: true,
  modules: {
    home: false,
    appreciate: false,
    feeds: false,
    rewards: true,
    myWallet: false,
    settings: false,
    pointsHistory: false,
    cart: false,
    leaderboard: false
  },
  panel: {
    link: [''],
    support: false,
    viewProfile: false
  },
  profileEdit: {
    editFormDisabled: true,
    navigateToHome: false,
    departmentName: true,
    taxId: true,
    taxPercentage: true,
    registrationDate: true,
  },
  rating: false,
  recognitionRecommendation: false,
  registerNewAccount: false,
  rewardDefaultSort: 'lh',
  rewardDetailBackRoute: ['/rewards/browse-all'],
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
    },
    sorting: true,
    title: 'common.reward.n',
  },
  showDepartmentName: true,
  showEmployeeId: true,
  showExpiringPoints: true,
  showPoints: false,
  showSpecialDeal: true,
  showUsername: false,
  sideCarousel: [],
  topMenu: true,
  showTierOnRewards: true
};
