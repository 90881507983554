export default {
  dev: {
    admin: 'https://skordev.com/admin-dashboard/#/report',
    app: 'https://skordev.com/',
    basePath: 'https://mastercard-pwa.skordev.com/#/',
    img: 'https://skordev.com',
  },
  prod: {
    admin: 'https://skordev.com/admin-dashboard/#/report',
    app: 'https://skordev.com/',
    basePath: 'https://mastercard-pwa.skordev.com/#/',
    img: 'https://skordev.com',
  },
};
