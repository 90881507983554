export default {
  header: {
    templateUrl: './top.header.component.html',
    styleUrls: ['./top.header.component.scss'],
  },
  home: {
    templateUrl: './rewards.home.component.html',
    styleUrls: ['./rewards.home.component.scss'],
  },
};
